import { TemplateDictionaryItemType } from '@gladiate/types';

export const templateDictionary: TemplateDictionaryItemType[] = [
  {
    name: "Attorney's Fee",
    value: 'attorneysFees_feePercentage',
    description: "Attorney's Fee",
    type: 'numeric',
  },
  {
    name: 'Current Date',
    value: 'currentDate',
    description: 'The current date in the format December 31, 2021',
    type: 'date',
  },
  {
    name: 'Contact Name (Organization)',
    value: 'contact_name',
    description: "The Contact's name when the contact is of type 'Organization'",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact First Name',
    value: 'contact_firstName',
    description: "The Contact's first name",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Last Name',
    value: 'contact_lastName',
    description: "The Contact's last name",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Full Name',
    value: 'contact_fullName',
    description: "The Contact's full name (This works for both individuals and organizations)",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Prefix',
    value: 'contact_prefix',
    description: "The Contact's Prefix",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Suffix',
    value: 'contact_suffix',
    description: "The Contact's Suffix",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Address',
    value: 'contact_address',
    description: "The Contact's full address (street, city, state, zip)",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Street',
    value: 'contact_address_streetAddress',
    description: "The Contact's street address",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact City',
    value: 'contact_address_city',
    description: "The Contact's city",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact State',
    value: 'contact_address_state',
    description: "The Contact's state",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Zip',
    value: 'contact_address_zip',
    description: "The Contact's zip code",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Date of Birth',
    value: 'contact_dateOfBirth',
    description: "The Contact's date of birth",
    type: 'date',
    parentResource: 'contact',
  },
  {
    name: 'Contact Social Security Number',
    value: 'contact_socialSecurityNumber',
    description: "The Contact's social security number",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Job Title',
    value: 'contact_job_title',
    description: "The Contact's job position",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Job Start Date',
    value: 'contact_job_startDate',
    description: "The Contact's job start date",
    type: 'date',
    parentResource: 'contact',
  },
  {
    name: 'Contact Number',
    value: 'contact_phoneNumber',
    description: "The Contact's Phone or Fax Number",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Contact Email Address',
    value: 'contact_emailAddress',
    description: "The Contact's email address",
    type: 'text',
    parentResource: 'contact',
  },
  {
    name: 'Incident City',
    value: 'case_incidentCity',
    description: 'The city where the incident occurred',
    type: 'text',
  },
  {
    name: 'Statute of Limitations',
    value: 'case_statuteOfLimitations',
    description: 'The statute of limitations for the case',
    type: 'date',
  },
  {
    name: 'Time of Incident',
    value: 'case_incidentTimeOfDay',
    description: 'The time of day the incident occurred',
    type: 'text',
  },
  {
    name: 'Incident Address',
    value: 'case_incidentAddress',
    description: 'The address where the incident occurred',
    type: 'text',
  },
  {
    name: 'Description of Incident',
    value: 'contact_firstResponder_connectedValue_descriptionOfIncident',
    description: 'Description of the incident',
    type: 'text',
  },
  {
    name: 'Description of Injuries',
    value: 'case_clientDescriptionOfIncident',
    description: 'Description of their injuries',
    type: 'text',
  },
  {
    name: 'Vehicle Year',
    value: 'vehicle_year',
    description: 'The year of the vehicle',
    type: 'text',
  },
  {
    name: 'Vehicle Make',
    value: 'vehicle_make',
    description: 'The make of the vehicle',
    type: 'text',
  },
  {
    name: 'Vehicle Model',
    value: 'vehicle_model',
    description: 'The model of the vehicle',
    type: 'text',
  },
  {
    name: 'Vehicle License Plate',
    value: 'vehicle_licensePlate',
    description: 'The license plate of the vehicle',
    type: 'text',
  },
  {
    name: 'Vehicle VIN',
    value: 'vehicle_vin',
    description: 'The VIN of the vehicle',
    type: 'text',
  },
  {
    name: 'Case Type',
    value: 'case_caseType',
    description: 'The type of case',
    type: 'text',
  },
  {
    name: 'Case Court County',
    value: 'case_courtCounty',
    description: "The Case's Court County",
    type: 'text',
  },
  {
    name: 'Case Name',
    value: 'case_caseName',
    description: 'The case name',
    type: 'text',
  },
  {
    name: 'Case Number',
    value: 'case_caseNumber',
    description: 'The case number',
    type: 'text',
  },
  {
    name: 'Contact Personal Pronoun',
    value: 'contact_pronounsPersonal',
    description: "The Contact's personal pronoun (e.g. He / She / Them)",
    type: 'text',
  },
  {
    name: 'Contact Possessive Pronoun',
    value: 'contact_pronounsPossessive',
    description: "The Contact's possessive pronoun (e.g. His / Her / Their)",
    type: 'text',
  },
  {
    name: 'Date of Incident',
    value: 'case_dateOfIncident',
    description: 'The date of the incident',
    type: 'date',
  },

  {
    name: 'Claim Number',
    value: 'insurance_claimNumber',
    description: 'Insurance Policy Claim Number',
    type: 'text',
  },
  {
    name: 'Effective Dates Of Coverage End',
    value: 'insurance_effectiveDatesOfCoverageEnd',
    description: 'Insurance policies effective end date',
    type: 'date',
  },
  {
    name: 'Effective Dates Of Coverage Start',
    value: 'insurance_effectiveDatesOfCoverageStart',
    description: 'Insurance policies effective start date',
    type: 'date',
  },
  {
    name: 'Liability Coverage Per Accident',
    value: 'insurance_liabilityCoveragePerAccident',
    description: 'Liability Coverage Amount Per Accident',
    type: 'numeric',
  },
  {
    name: 'Liability Coverage Per Person',
    value: 'insurance_liabilityCoveragePerPerson',
    description: 'Liability Coverage Amount Per Person',
    type: 'numeric',
  },
  {
    name: 'Medical Payment Coverage',
    value: 'insurance_medicalPaymentCoverageAmount',
    description: 'Medical Payment Coverage Amount',
    type: 'numeric',
  },
  {
    name: 'Medical Treatment Table',
    value: '',
    description: 'Copy entire medical treatments table to your clipboard',
    type: 'table',
  },
  {
    name: 'Expense Table',
    value: '',
    description: 'Copy entire expense table to your clipboard',
    type: 'table',
  },
  {
    name: 'Lien Table',
    value: '',
    description: 'Copy entire lien table to your clipboard',
    type: 'table',
  },
  {
    name: 'Policy Number',
    value: 'insurance_policyNumber',
    description: 'Policy Number of the selected insurance policy',
    type: 'text',
  },
  {
    name: 'Umbrella Policy',
    value: 'insurance_umbrellaPolicy',
    description: 'Umbrella Policy Amount',
    type: 'numeric',
  },
  {
    name: 'Firm Referring Case',
    value: 'contact_referralFromFirm_name',
    description: 'Firm Referring Case',
    type: 'text',
  },
  {
    name: "Assigned Staff's Name",
    value: 'staff_name',
    description: "Assigned Staff's Name",
    type: 'text',
  },
  {
    name: "Assigned Staff's Email",
    value: 'staff_email',
    description: "Assigned Staff's Email",
    type: 'text',
  },
  {
    name: "Assigned Staff's Number",
    value: 'staff_number',
    description: "Assigned Staff's Phone or Fax Number",
    type: 'text',
  },
  {
    name: 'Law Firm Name',
    value: 'firm_firmName',
    description: "The Law Firm's Name",
    type: 'text',
  },
  {
    name: 'Law Firm Address',
    value: 'firm_address',
    description: "The Law Firm's Address (Street, City, State, Zip)",
    type: 'text',
  },
  {
    name: 'Blank Checkbox',
    value: '[]',
    description: 'A blank checkbox',
    type: 'input',
  },
  {
    name: 'Input Field',
    value: 'textInput_es_:signer1',
    description: 'A text input field',
    type: 'input',
  },
  {
    name: 'Multi-line Input Field',
    value: 'textInputMultiLine1_es_:signer1:multiline',
    description: 'A multi-line text input field',
    type: 'input',
  },
  {
    name: 'Signature (initials)',
    value: 'Int_es_:signer1:initials',
    description: 'Signer initials',
    type: 'input',
  },
  {
    name: 'Signature (full)',
    value: 'Sig_es_:signer1:signature',
    description: 'Signer full signature',
    type: 'input',
  },
  {
    name: 'Settlement Date issued',
    value: 'settlement_dateIssued',
    description: 'Settlement Date issued',
    type: 'date',
  },
  {
    name: 'Settlement Expiration date',
    value: 'settlement_expirationDate',
    description: 'Settlement Expiration Date',
    type: 'date',
  },
  {
    name: 'Date Signed',
    value: 'Dte_es_:signer1:date',
    description: 'Date Signed',
    type: 'input',
  },
  {
    name: 'Settlement Amount',
    value: 'settlement_amount',
    description: 'The settlement amount',
    type: 'numeric',
  },
  {
    name: 'Court Name',
    value: 'case_courtName',
    description: 'Court Name',
    type: 'text',
  },
  {
    name: 'Court Type',
    value: 'case_courtType',
    description: 'Court Type',
    type: 'text',
  },
  {
    name: 'Court Location',
    value: 'case_courtLocation',
    description: 'Court Location',
    type: 'text',
  },
  {
    name: 'Co-Counsel Contact Name',
    value: 'cocounsel_name',
    description: 'Co-Counsel Contact Name',
    type: 'text',
  },
  {
    name: 'Co-Counsel Firm Name',
    value: 'cocounsel_cocounselName',
    description: 'Co-Counsel Firm Name',
    type: 'text',
  },
  {
    name: 'Co-Counsel Address',
    value: 'cocounsel_address',
    description: 'Co-Counsel Address',
    type: 'text',
  },
];

import { useGetResourceSlideoverItems } from '@colosseum/data';
import { ResourceAttachmentType, TaskTypeOptionsType } from '@gladiate/types';
import { kebabCase } from 'lodash';
import React, { useState } from 'react';
import ActionConfirmModal from '../ActionConfirmModal/ActionConfirmModal';
import ResourceFileAttachmentSection from '../ResourceFileAttachmentSection/ResourceFileAttachmentSection';
import ResourceHistory from '../ResourceHistory/ResourceHistory';
import ResourceNotes from '../ResourceNotes/ResourceNotes';
import ResourceTasks from '../ResourceTasks/ResourceTasks';
import Slideover, { SlideoverProps } from '../Slideover/Slideover';
import { TabsContent } from '../shadcn/Tabs/Tabs';

export interface ResourceSlideoverProps
  extends Pick<
    SlideoverProps,
    | 'bottomButtons'
    | 'defaultTab'
    | 'deleteButtonText'
    | 'deleteFunction'
    | 'description'
    | 'displayDeleteButton'
    | 'open'
    | 'setOpen'
    | 'title'
    | 'typing'
    | 'deleteModalTitle'
    | 'deleteModalDescription'
  > {
  createType: TaskTypeOptionsType | 'contact';
  children?: React.ReactNode;
  resourceId: string;
  caseId?: string;
  hideSections?: {
    details?: boolean;
    attachments?: boolean;
    notes?: boolean;
    tasks?: boolean;
    feed?: boolean;
  };
  onClose?: () => void;
}

export function ResourceSlideover(props: ResourceSlideoverProps) {
  const {
    caseId,
    children,
    createType,
    hideSections = {
      details: false,
      attachments: false,
      notes: false,
      tasks: false,
      feed: false,
    },
    resourceId,
    deleteFunction,
    deleteModalTitle,
    deleteModalDescription,
    setOpen,
    ...rest
  } = props;
  const { notesCount, tasksCount, attachmentsCount, audits, auditsCount } =
    useGetResourceSlideoverItems(resourceId);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const tabHeaders = [];
  if (!hideSections.details) {
    tabHeaders.push({ name: 'Details', value: 'details' });
  }
  if (!hideSections.attachments) {
    tabHeaders.push({
      name: 'Attachments',
      value: 'attachments',
      count: attachmentsCount.toString(),
    });
  }
  if (!hideSections.notes) {
    tabHeaders.push({
      name: 'Notes',
      value: 'notes',
      count: notesCount.toString(),
    });
  }
  if (!hideSections.tasks) {
    tabHeaders.push({
      name: 'Tasks',
      value: 'tasks',
      count: tasksCount.toString(),
    });
  }
  if (!hideSections.feed) {
    tabHeaders.push({
      name: 'Feed',
      value: 'feed',
      count: auditsCount.toString(),
      color: 'orange',
    });
  }

  return (
    <Slideover
      tabHeaders={tabHeaders}
      {...rest}
      setOpen={setOpen}
      deleteFunction={deleteFunction}
      deleteModalTitle={deleteModalTitle}
      resourceId={resourceId}
      resourceType={createType}
    >
      <div>{children}</div>
      <TabsContent value="notes">
        {!hideSections.notes ? (
          <div className="flex flex-col gap-y-4">
            <ResourceNotes
              createType={createType ?? 'case'}
              resourceId={resourceId ?? ''}
              caseId={caseId ?? ''}
            />
          </div>
        ) : null}
      </TabsContent>
      <TabsContent value="tasks">
        {!hideSections.tasks ? (
          <div className="flex flex-col gap-y-4">
            <ResourceTasks
              createType={createType !== 'contact' ? createType : 'case'}
              resourceId={resourceId ?? ''}
              isPaginated={false}
            />
          </div>
        ) : null}
      </TabsContent>
      <TabsContent value="feed">
        {!hideSections.feed ? (
          <div className="flex flex-col gap-y-4">
            <ResourceHistory
              audits={audits}
              isAuditsLoading={audits.isLoading}
              setNotificationsOnly={audits.setNotificationsOnly}
              notificationsOnly={audits.notificationsOnly}
            />
          </div>
        ) : null}
      </TabsContent>
      <TabsContent value="attachments">
        {!hideSections.attachments ? (
          <ResourceFileAttachmentSection
            resourceId={resourceId ?? ''}
            caseId={caseId ?? ''}
            type={kebabCase(createType) as ResourceAttachmentType}
          />
        ) : null}
      </TabsContent>
      <ActionConfirmModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        actionFunction={deleteFunction}
        actionFunctionIsMutation={true}
        callback={() => setOpen(false)}
        title={deleteModalTitle}
        description={deleteModalDescription}
      />
    </Slideover>
  );
}

export default ResourceSlideover;

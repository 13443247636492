import {
  CaseCompleteType,
  VITE_API_STAGE,
  apiStageOptions,
  cn,
  copyToClipboard,
  objectToQueryString,
  statusToCategoryMapping,
  tanstackTableNames,
  useCreateCase,
  useCreateCaseContactConnection,
  useCreateContact,
  useCreateContactResource,
  useCreateTimeLog,
  useDeletePresetFilter,
  useGetAllTimeLogs,
  useGetCasesComplete,
  useGetCurrentUser,
  useGetPresetFilters,
  usePermissions,
} from '@colosseum/data';
import {
  ActionConfirmModal,
  Button,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuSeparator,
  CustomErrorBoundary,
  DataTable,
  DataTableProps,
  DataTabs,
  GladiateLoader,
  QuickCreateCaseSlideover,
  ResourceSlideover,
  Sparkles,
  Typography,
} from '@colosseum/shared-ui';
import { ContactEmailType, ContactNumberType, PresetFilterType } from '@gladiate/types';
import { AdjustmentsHorizontalIcon, TableCellsIcon } from '@heroicons/react/20/solid';
import {
  BoltIcon,
  FunnelIcon,
  RectangleStackIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';
import { withErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { VisibilityState } from '@tanstack/react-table';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import CaseReviewView from '../case/CaseReviewView/CaseReviewView';
import ErrorPage from '../error/ErrorPage';
import FiltersSlideover from './FiltersSlideover/FiltersSlideover';
import PresetFilterSelect from './PresetFilterSelect/PresetFilterSelect';
import { columns } from './case-table-columns';

const API_STAGE = VITE_API_STAGE;

type activeCaseFilterOptions = 'Open' | 'Closed' | 'Referred' | 'Declined' | 'All' | undefined;

/* eslint-disable-next-line */
export interface CasesPageProps {}

export function CasesPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isCocounsel } = usePermissions();

  const { data: presetFilters } = useGetPresetFilters();

  const deletePresetFilter = useDeletePresetFilter();

  const params = new URLSearchParams(location.search);
  const paramsSize = [...new Set(params.keys())].length;

  // case page view
  const [viewType, setViewType] =
    useState<DataTableProps<CaseCompleteType, CaseCompleteType>['viewType']>('table');
  const [activeFilter, setActiveFilter] = useState<activeCaseFilterOptions>('Open');

  const [activePresetFilter, setActivePresetFilter] = useState<PresetFilterType | undefined>();

  const [quickIntakeAnimation, setQuickIntakeAnimation] = useState(false);
  const [filterSlideoverOpen, setFilterSlideoverOpen] = useState(false);
  const [activeCaseId, setActiveCaseId] = useState<string>();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [presetFilterToDelete, setPresetFilterToDelete] = useState<string | undefined>(undefined);

  const [slideoverOpen, setSlideoverOpen] = useState(false);
  const [initialSlideoverTab, setInitialSlideoverTab] = useState<'notes' | 'tasks' | 'feed'>(
    'notes',
  );

  // Query Params
  const [searchParams, setSearchParams] = useSearchParams();
  const [displayQuickCaseCreation, setDisplayQuickCaseCreation] = useState(
    searchParams.has('open-quick-intake') ?? false,
  );

  // REACT QUERY
  const casesQuery = useGetCasesComplete();
  const casesEntities = casesQuery.data?.data ?? [];
  const cases = Object.values(casesEntities); // it was complaining about about stuff so I added object as well
  const activeCase = cases.find((caseItem) => caseItem.caseId === activeCaseId);
  const createCase = useCreateCase();

  const createContact = useCreateContact();
  const createTimeLog = useCreateTimeLog();
  const createCaseContactConnection = useCreateCaseContactConnection();
  const createContactPhoneNumber = useCreateContactResource<ContactNumberType>('numbers');
  const createContactEmail = useCreateContactResource<ContactEmailType>('emails');
  const currentUser = useGetCurrentUser();
  const { data: allTimeLogsData, isLoading: isAllTimelogsLoading } = useGetAllTimeLogs(
    currentUser?.data?.data?.Username,
  );
  const unfinishedTimeLog = useMemo(
    () => allTimeLogsData?.data?.find((timeLog) => !timeLog.endTime),
    [allTimeLogsData],
  );

  const handleCreateTimeLog = (itemId: string) => {
    if (itemId) {
      createTimeLog.mutate({
        itemId,
        type: 'case',
      });
    }
  };

  const handleNavigateToCasePage = useCallback(
    (id: string) => {
      if (
        !unfinishedTimeLog &&
        !isAllTimelogsLoading &&
        currentUser?.data?.data?.automaticTimerStart === '1'
      ) {
        handleCreateTimeLog(id);
      }
      navigate(`/cases/${id}`);
    },
    [navigate, unfinishedTimeLog, isAllTimelogsLoading, currentUser?.data?.data],
  );

  const queryParams = getAllQueryParams();

  // append id to existing path and redirect to that url
  const handleRowClick = useCallback(
    (row: { original: CaseCompleteType }) => {
      const id = row?.original?.caseId;

      if (!id) {
        return;
      }

      return handleNavigateToCasePage(id);
    },
    [handleNavigateToCasePage],
  );

  useEffect(() => {
    queryClient.removeQueries({
      queryKey: ['arcClient'],
    });
    queryClient.removeQueries({
      queryKey: ['conscript'],
    });

    if (Object.keys(queryParams).length) {
      if (queryParams?.tillStatute) {
        if (!/^([1-9]\d*)$/.test(queryParams?.tillStatute)) {
          removeAllQueryParams();
        }
      }
    }
  }, []);

  //sort cases by date
  const sortedAndFilteredCases = cases.sort((a, b) => {
    if (!a?.caseOpenDate || !b?.caseOpenDate) return 0;

    return new Date(b.caseOpenDate).getTime() - new Date(a.caseOpenDate).getTime();
  });

  const filteredByStatus = sortedAndFilteredCases.filter((caseItem) => {
    const status = caseItem.caseStatus?.category ?? '';

    if (caseItem.caseStatus?.title?.toLowerCase() === 'lead') {
      return false;
    }

    if (activeFilter === 'All') {
      return true;
    } else {
      if (activeFilter === statusToCategoryMapping[status]) {
        return true;
      } else {
        return false;
      }
    }
  });

  function getStatusCount(passedInStatus: activeCaseFilterOptions) {
    let count = 0;
    sortedAndFilteredCases.forEach((caseData) => {
      const status = caseData.caseStatus?.category;
      if (passedInStatus === statusToCategoryMapping[status ?? '']) {
        count++;
      }
    });
    return count;
  }

  function createCaseById() {
    createCase.mutateAsync({}).then((caseRes) => {
      const id = caseRes?.data.caseId;

      if (!id) {
        enqueueSnackbar('Error creating case', {
          variant: 'error',
        });
        return;
      }
      handleNavigateToCasePage(id);
    });
  }

  function getAllQueryParams() {
    // Create a URLSearchParams object
    const searchParams = new URLSearchParams(location.search);

    // Iterate through the searchParams and store them as key-value pairs in an object
    const queryParams: any = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    return queryParams as {
      tillStatute?: string;
    };
  }

  function removeAllQueryParams() {
    // Navigate to the current pathname without any query parameters
    navigate(location.pathname, { replace: true });
  }

  const handlePresetFilterSelect = (presetFilterIdParam: string) => {
    const selectedPresetFilter = presetFilters?.data?.find(
      (presetFilter) => presetFilter.presetFilterId === presetFilterIdParam,
    ) as PresetFilterType & { Name: string };
    setActivePresetFilter(selectedPresetFilter);
    if (!selectedPresetFilter) {
      return;
    }

    const {
      title,
      description,
      firmId,
      dateModified,
      dateCreated,
      presetFilterId,
      Name,
      ...presetFilterQueryObject
    } = selectedPresetFilter;

    // Convert the query object to a query string
    const queryString = objectToQueryString(presetFilterQueryObject);

    // Navigate to the current page with the new query parameters
    navigate(`?${queryString}`, { replace: true });
  };

  const handleCopyCaseEmail = (data: CaseCompleteType) => {
    if (!data) {
      return;
    }
    if (API_STAGE === apiStageOptions.prod) {
      copyToClipboard(`case+${data?.secureReferenceId}@forwarding.gladiatelaw.com`);
    } else {
      copyToClipboard(`case.${API_STAGE}+${data?.secureReferenceId}@forwarding.gladiatelaw.com`);
    }
  };

  const createCaseLoading =
    createCase.isLoading ||
    createContact.isLoading ||
    createCaseContactConnection.isLoading ||
    createContactPhoneNumber.isLoading ||
    createContactEmail.isLoading;

  const renderContextMenuContent = useCallback(
    (data: CaseCompleteType) => (
      <ContextMenuContent>
        <ContextMenuLabel inset className="px-6">
          {data.caseTitle ?? 'No Title'}
        </ContextMenuLabel>
        <ContextMenuSeparator />
        <ContextMenuItem
          className="px-6"
          onSelect={() => {
            setActiveCaseId(data?.caseId);
            setInitialSlideoverTab('notes');
            setTimeout(() => {
              setSlideoverOpen(true);
            }, 500);
          }}
        >
          Quick View Notes
        </ContextMenuItem>
        <ContextMenuItem
          className="px-6"
          onSelect={() => {
            setActiveCaseId(data?.caseId);
            setInitialSlideoverTab('tasks');
            setTimeout(() => {
              setSlideoverOpen(true);
            }, 500);
          }}
        >
          Quick View Tasks
        </ContextMenuItem>

        <ContextMenuItem
          className="px-6"
          onSelect={() => {
            setActiveCaseId(data?.caseId);
            setInitialSlideoverTab('feed');
            setTimeout(() => {
              setSlideoverOpen(true);
            }, 500);
          }}
        >
          Quick view Feed
        </ContextMenuItem>

        <ContextMenuItem
          className="px-6"
          onSelect={() => {
            handleCopyCaseEmail(data);
          }}
        >
          Copy Case Email Address
        </ContextMenuItem>
      </ContextMenuContent>
    ),
    [],
  );

  useEffect(() => {
    if (!slideoverOpen) {
      setActiveCaseId(undefined);
    }
  }, [slideoverOpen]);

  if (casesQuery.isError) {
    return (
      <ErrorPage
        code={500}
        errorTitle={'Something went wrong'}
        errorMessage={'We are working to get this resolved as soon as possible!'}
        backRoute="/home"
        backMessage="Back to Home"
      />
    );
  }

  return (
    <>
      <Helmet defer={false}>
        <title>Cases</title>
      </Helmet>
      <div className="relative mt-5 bg-white border shadow-sm rounded-xl" data-cy="cases-list-view">
        <div className="flex w-full px-4 mb-5">
          <div className="w-full">
            <div className="flex flex-wrap items-end justify-between">
              <Typography tag="h1" variant="pageHeading">
                Cases
              </Typography>
              <div className="flex flex-wrap items-end space-y-5 ">
                <div className="px-1 py-1 mr-5 -mb-0.5 border border-gray-100 rounded-lg shadow-lg mt-5 sm:mt-0">
                  <span className="inline-flex rounded-md isolate">
                    <Button
                      variant={viewType === 'table' ? 'outlinePrimary' : 'ghost'}
                      textColor={viewType === 'table' ? 'default' : 'gray'}
                      className="relative inline-flex items-center px-4 py-1 "
                      onClick={() => {
                        setViewType('table');
                      }}
                    >
                      <TableCellsIcon className="w-5 h-5 mr-2" />
                      Table
                    </Button>
                    <Button
                      variant={viewType === 'card' ? 'outlinePrimary' : 'ghost'}
                      textColor={viewType === 'card' ? 'default' : 'gray'}
                      className="relative inline-flex items-center px-4 py-1 "
                      onClick={() => {
                        setViewType('card');
                      }}
                    >
                      <Squares2X2Icon className="w-4 h-4 mr-1" />
                      Card
                    </Button>
                    <Button
                      variant={viewType === 'timeline' ? 'outlinePrimary' : 'ghost'}
                      textColor={viewType === 'timeline' ? 'default' : 'gray'}
                      className="relative inline-flex items-center px-4 py-1"
                      onClick={() => {
                        setViewType('timeline');
                      }}
                    >
                      <AdjustmentsHorizontalIcon className="w-4 h-4 mr-1" />
                      Timeline
                    </Button>
                    <Button
                      variant={viewType === 'review' ? 'outlinePrimary' : 'ghost'}
                      textColor={viewType === 'review' ? 'default' : 'gray'}
                      className="relative inline-flex items-center px-4 py-1"
                      onClick={() => {
                        setViewType('review');
                      }}
                    >
                      <RectangleStackIcon className="w-4 h-4 mr-1" />
                      Review
                    </Button>
                  </span>
                </div>
                {!isCocounsel && (
                  <div className="inline-flex rounded-md shadow-lg whitespace-nowrap">
                    <Button
                      variant="primary"
                      data-cy="create-case-button"
                      className="relative rounded-r-none px-4 py-2.5 focus:z-10"
                      onClick={() => {
                        if (!createCaseLoading) {
                          createCaseById();
                        }
                      }}
                      disabled={createCaseLoading}
                      type="button"
                    >
                      {createCaseLoading ? (
                        <div className="w-16">
                          <GladiateLoader white={true} height={20} />
                        </div>
                      ) : (
                        'Create Case'
                      )}
                    </Button>

                    <div className="relative block -ml-px">
                      <Button
                        variant="primary"
                        disabled={createCaseLoading}
                        onClick={() => {
                          setQuickIntakeAnimation(true);

                          setTimeout(() => {
                            setDisplayQuickCaseCreation(true);
                            setQuickIntakeAnimation(false);
                          }, 500);
                        }}
                        className="relative inline-flex items-center px-2 py-2.5 ml-0.5 rounded-l-none focus:z-10"
                      >
                        <span className="sr-only">Open options</span>
                        {quickIntakeAnimation ? (
                          <Sparkles>
                            <BoltIcon
                              className="w-5 h-5 text-yellow-400 animate__animated animate__tada"
                              aria-hidden="true"
                            />
                          </Sparkles>
                        ) : (
                          <BoltIcon className={'w-5 h-5'} aria-hidden="true" />
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <DataTabs<activeCaseFilterOptions>
              filters={[
                { title: 'Open', count: getStatusCount('Open'), value: 'Open' },
                {
                  title: 'Closed',
                  count: getStatusCount('Closed'),
                  value: 'Closed',
                },
                {
                  title: 'Referred',
                  count: getStatusCount('Referred'),
                  value: 'Referred',
                },
                {
                  title: 'Declined',
                  count: getStatusCount('Declined'),
                  value: 'Declined',
                },
              ]}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
            />
          </div>
        </div>
        <div
          className={cn(
            'px-5 pb-5',
            viewType === 'review' && 'grid grid-cols-2 gap-x-2 overflow-hidden min-h-[675px]',
          )}
        >
          <DataTable
            viewType={viewType}
            activeCase={activeCase}
            setActiveCaseId={setActiveCaseId}
            data={filteredByStatus}
            columns={columns}
            handleRowClick={handleRowClick}
            handleResetFilters={() => {
              setActivePresetFilter(undefined);
            }}
            initialSort={{
              id: 'Open Date',
              desc: true,
            }}
            customRightButton={
              <div className="inline-flex h-8 rounded-md whitespace-nowrap">
                <Button
                  variant="outline"
                  size="unset"
                  className="relative px-2 ml-2 rounded-r-none focus:z-10"
                  onClick={() => {
                    setFilterSlideoverOpen(true);
                  }}
                >
                  <FunnelIcon className="w-5 h-5 mr-1" />
                  <Typography className="max-w-[200px] truncate">
                    {activePresetFilter?.title || 'Filters'}
                  </Typography>
                  {paramsSize > 0 && (
                    <div className="absolute top-[-7px] w-4 h-4 left-[-7px] bg-sky-blue text-white leading-[16px] rounded-xl text-xs">
                      {paramsSize}
                    </div>
                  )}
                </Button>
                <PresetFilterSelect
                  handleItemSelect={handlePresetFilterSelect}
                  activeFilter={activePresetFilter}
                  triggerClassName="relative top-[-1px] inline-flex items-center px-1.5 h-8 text-sm border border-gray-200 rounded-none rounded-r-md focus:z-10 focus:ring-0 focus:ring-offset-0"
                />
              </div>
            }
            showSearchBar
            isLoading={casesQuery.isLoading}
            filtersInParams
            initialVisibility={
              {
                'Case Status Goal': false,
                'Date Created': false,
                'Days in Status': false,
                'Filing Date': false,
                'Injection Count': false,
                'MRI Count': false,
                'Surgery Count': false,
                'Trial Date': false,
                'Uncompleted Tasks': false,
                Assignees: false,
                Conflicts: false,
                Expenses: false,
                Tags: false,
                Treatments: false,
              } as VisibilityState
            }
            persistentVisibility={{
              // TODO: Remove these fields and filter using existing fields
              // These take priority over user preferences, even if the user has column preferences in local storage
              'Marketing Referral Source ID': false,
              'Third Party Coverage Person': false,
              'Third Party Coverage Accident': false,
              'UM/UIM Coverage Person': false,
              'UM/UIM Coverage Accident': false,
              'Uncompleted Tasks Percentage': false,
              'Days in Status Relative to Goal': false,
            }}
            tableName={tanstackTableNames.cases}
            showCSVDownload
            renderContextMenuContent={renderContextMenuContent}
          />
          {viewType === 'review' && <CaseReviewView activeCase={activeCase} />}
        </div>
      </div>
      <ResourceSlideover
        createType="case"
        title={activeCase?.caseTitle ?? ''}
        description="Quick actions for this case"
        open={slideoverOpen}
        resourceId={activeCaseId ?? ''}
        caseId={activeCaseId}
        setOpen={setSlideoverOpen}
        defaultTab={initialSlideoverTab}
        displayDeleteButton={false}
      />
      <QuickCreateCaseSlideover
        open={displayQuickCaseCreation}
        setOpen={setDisplayQuickCaseCreation}
      />
      <FiltersSlideover
        handlePresetFilterSelect={handlePresetFilterSelect}
        open={filterSlideoverOpen}
        setOpen={setFilterSlideoverOpen}
        activePresetFilter={activePresetFilter}
        setActivePresetFilter={setActivePresetFilter}
      />
      <ActionConfirmModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        actionFunction={() => {
          if (presetFilterToDelete) {
            deletePresetFilter.mutateAsync(presetFilterToDelete).then(() => {
              setPresetFilterToDelete(undefined);
              setDeleteModalOpen(false);
            });
          }
        }}
      />
    </>
  );
}

export default withErrorBoundary(CasesPage, { fallback: CustomErrorBoundary });

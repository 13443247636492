import { SubscriptionType } from './Payment';
import { BitBoolean } from './global';

export type HurinUserAttributeType = {
  Name: string;
  Value: string;
};

export const PAGES_MAP = {
  sectionAutomations: 'Automations',
  sectionCases: 'Cases',
  sectionContacts: 'Contacts',
  sectionDashboard: 'Dashboard',
  sectionInsights: 'Insights',
  sectionSettings: 'Settings',
  sectionSubscriptions: 'Subscriptions',
  sectionTemplates: 'Templates',
  sectionCampaigns: 'Campaigns',
  sectionLeads: 'Leads',
};

export const PAGES_SUBSECTIONS = {
  sectionCases: ['summary', 'calendar'],
};

/**
 * Mapping of sections that are available to subscription types
 */
export const SUBSCRIPTION_ACCESS_MAP: {
  [key in SubscriptionType]: Partial<keyof typeof PAGES_MAP | ''>[];
} = {
  clientEngagement: [
    'sectionContacts',
    'sectionSettings',
    'sectionCampaigns',
    'sectionSubscriptions',
  ],
  caseOptimization: [
    'sectionAutomations',
    'sectionCases',
    'sectionContacts',
    'sectionDashboard',
    'sectionInsights',
    'sectionSettings',
    'sectionSubscriptions',
    'sectionTemplates',
    'sectionLeads',
  ],
  coCounsel: [
    'sectionAutomations',
    'sectionCases',
    'sectionContacts',
    'sectionDashboard',
    'sectionInsights',
    'sectionSettings',
    'sectionSubscriptions',
    'sectionTemplates',
    'sectionCampaigns',
    'sectionLeads',
  ],
  leadManagement: ['sectionLeads'],
  insight: ['sectionInsights'],
};

export type HurinUserType = {
  username?: string;
  groupId?: string;
  Attributes?: HurinUserAttributeType[];
  automaticTimerStart?: '1' | '0';
  automaticTimerStop?: '1' | '0';
  UserAttributes?: HurinUserAttributeType[];
  checked?: boolean;
  'custom:featureAccess'?: string;
  'custom:portalStatus'?: string;
  email_verified?: boolean;
  email?: string;
  Enabled?: boolean;
  name?: string;
  phone_number?: string;
  sub?: string;
  UserCreateDate?: string;
  UserLastModifiedDate?: string;
  Username?: string;
  UserStatus?: string;
  phone_number_verified?: boolean;
  desiredDeliveryMediums?: string[];
  groupName?: string;
  subscriptionCaseOptimizationId?: string;
  subscriptionClientEngagementId?: string;
  subscriptionCoCounselId?: string;
  subscriptionLeadManagementId?: string;
  subscriptionInsightId?: string;
};

export type PermissionType = {
  automationsOneClickSignAccess: BitBoolean;
  automationsTaskPlansAccess: BitBoolean;
  automationsFileRepoStructureAccess: BitBoolean;
  automationsLitigationPlansAccess: BitBoolean;
  automationsFirmSurveysAccess: BitBoolean;

  caseSummaryAccess: BitBoolean;
  caseCollaborationHubAccess: BitBoolean;
  caseIncidentAccess: BitBoolean;
  caseMedicalsAccess: BitBoolean;
  caseAccountingAccess: BitBoolean;
  caseNegotiationsAccess: BitBoolean;
  caseLitigationAccess: BitBoolean;
  caseClosingAccess: BitBoolean;
  caseFilesAccess: BitBoolean;
  caseCustomAttributesAccess: BitBoolean;
  caseCalendarAccess: BitBoolean;

  settingsUserAccess: BitBoolean;
  settingsFirmDetailsAccess: BitBoolean;
  settingsAttorneysFeesAccess: BitBoolean;
  settingsCaseDefaultsAccess: BitBoolean;
  settingsTimeTrackingAccess: BitBoolean;
  settingsMedicalTreatmentTypesAccess: BitBoolean;
  settingsExpenseCategoriesAccess: BitBoolean;
  settingsCustomAttributesAccess: BitBoolean;
  settingsFirmCalendarAccess: BitBoolean;
  settingsESignAccess: BitBoolean;
  settingsPhoneNumbersAccess: BitBoolean;
};

type PermissionsAndPagesType = PermissionType & typeof PAGES_MAP;

export interface GroupPermission extends PermissionsAndPagesType {
  dateCreated: string;
  dateModified: string;
  firmId: string;
  groupDescription: string;
  groupId: string;
  groupName: string;
}

export interface UpdateUserAttributesVariables {
  userAttributes: HurinUserAttributeType[];
  successCb?: (result: any) => void;
  errorCb?: (error: any) => void;
}

export interface FirmSignersRes {
  Signers: {
    email: string;
    name: string;
  }[];
}

export interface CoCounselType {
  firmId: string;
  username: string;
  dateCreated?: string;
  dateModified?: string;

  cocounselName?: string;
  cocounselFirmAddress?: string;
  cocounselFirmCity?: string;
  cocounselFirmState?: string;
  cocounselFirmZip?: string;
  cocounselEmail?: string;
  cocounselNumber?: string;
  cocounselDefaultFeeAgreement?: number;
}

export interface CaseAccessType {
  firmId: string;
  userAccessId: string;
  dateCreated?: string;
  dateModified?: string;

  caseId: string;
}

import {
  useCreateAdobeSignUserAccountV3,
  useCreateNylasAccountWithAuthCode,
  useGetAdobeAuthUrlV3,
  useGetAdobeUserListV3,
  useGetAdobeUserV3,
  useGetCurrentUser,
  useGetNylasAuthUrl,
  useGetPrimaryFirmCalendar,
  useGetUserNylasAccount,
  useSendAuthedAdobeSignUserCodeV3,
  useUpdateAdobeSignUserStatusV3,
  useUpdateFirmUserAttributes,
} from '@colosseum/data';
import { useEffect, useState } from 'react';

import {
  CustomErrorBoundary,
  LabyrinthTextInput,
  SectionContainer,
  TextSkeleton,
  TooltipWrapper,
  TypingDots,
  Typography,
} from '@colosseum/shared-ui';
import { LabyrinthUpdateParams, adobeUserStatus } from '@gladiate/types';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { Login as MicrosoftLogin } from '@microsoft/mgt-react';
import { withErrorBoundary } from '@sentry/react';
import { enqueueSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import NotificationPreferences from './NotificationPreferences/NotificationPreferences';
import TimeLogPreferences from './TimeLogPreferences/TimeLogPreferences';

/* eslint-disable-next-line */
export interface ProfilePageProps {}

const USER_ATTRIBUTE_MAP = [
  { label: 'Full name', key: 'name' },
  { label: 'Phone', key: 'phone_number' },
  { label: 'Email address', key: 'email' },
] as {
  label: 'Full name' | 'Email address' | 'Phone';
  key: 'name' | 'email' | 'phone_number';
}[];

export function ProfilePage(props: ProfilePageProps) {
  const navigate = useNavigate();
  const { integration } = useParams();

  const url = window.location.href;

  const currentUser = useGetCurrentUser();
  const firmUser = currentUser.data?.data;
  const userMutation = useUpdateFirmUserAttributes(firmUser?.Username ?? '');

  //!ADOBE
  //queries
  const { data: adobeAuthUrlData, isLoading: isAdobeAuthUrlLoading } = useGetAdobeAuthUrlV3();
  const adobeAuthUrl = adobeAuthUrlData?.data?.authUrl;

  const {
    data: adobeUserData,
    isLoading: isAdobeUserLoading,
    isError: isAdobeUserError,
    error: adobeUserError,
  } = useGetAdobeUserV3(firmUser?.username || '');

  const { data: adobeUserList, isLoading: isAdobeUserListLoading } = useGetAdobeUserListV3();

  //derived states
  const adobeUserNotFound = adobeUserError?.response?.status === 404;
  const adobeUserNeedsAuth = adobeUserError?.response?.status === 403;
  const currentAdobeUser = adobeUserList?.data.find((item) => item.username === firmUser?.username);

  const adobeUserAccountNotFound = adobeUserData?.data.status === adobeUserStatus.notFound; // 'NOT_FOUND';

  const adobeUserAccountInactive =
    adobeUserData?.data.adobeUser?.status === adobeUserStatus.inactive; //'INACTIVE';

  const adobeUserAccountCreated = currentAdobeUser?.userStatus === adobeUserStatus.created; // 'CREATED';

  const updateAdobeSignUserStatus = useUpdateAdobeSignUserStatusV3();
  const sendAuthedCode = useSendAuthedAdobeSignUserCodeV3();
  const createNewAdobeUserAccount = useCreateAdobeSignUserAccountV3();

  //!NYLAS
  const nylasAccountQuery = useGetUserNylasAccount();
  const nylasAccount = nylasAccountQuery.data?.data;
  const nylasAccountNotFound = nylasAccount === undefined;
  const nylasAuthUrlQuery = useGetNylasAuthUrl(!nylasAccountNotFound);
  const nylasAuthUrlData = nylasAuthUrlQuery?.data?.data;
  const nylasAuthUrl = nylasAuthUrlData?.authUrl;
  const createNylasAccountMutation = useCreateNylasAccountWithAuthCode();
  const primaryCalendarQuery = useGetPrimaryFirmCalendar();
  const primaryCalendarQueryData = primaryCalendarQuery.data?.data;
  const primaryCalendarQueryNotFound = primaryCalendarQueryData === undefined;
  const [saved, setSaved] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setSaved(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeParam = urlParams.get('code');
    if (codeParam) {
      if (integration === 'nylasIntegration') {
        createNylasAccountMutation
          .mutateAsync({
            authCode: codeParam,
          })
          .then(() => {
            navigate('/profile');
          });
      } else if (integration === 'adobeIntegration') {
        if (localStorage.getItem('adobeState') !== url.split('state=')[1]) {
          setTimeout(() => {
            localStorage.removeItem('adobeState');
            localStorage.removeItem('adobeCreateNewUser');
            enqueueSnackbar('Invalid Adobe Sign state. Please try again.', {
              variant: 'error',
            });
          }, 500);
          return;
        } else {
          const newUrl: string[] = url.split('?code=');

          const passUrl = newUrl[1].split('&state=');

          sendAuthedCode.mutateAsync(passUrl[0]).then((res) => {
            navigate('/profile');
            if (localStorage.getItem('adobeCreateNewUser')) {
              createNewAdobeUserAccount.mutate();
            }

            updateAdobeSignUserStatus.mutate({
              status: 'ACTIVE',
              username: firmUser?.username || '',
            });
          });
        }
      }
    }
  }, [window.location.search]);

  return (
    <div className="" data-cy="profile">
      <Helmet defer={false}>
        <title>Profile</title>
      </Helmet>
      <SectionContainer>
        <div className="flex items-center">
          <h1 className="ml-1 text-3xl font-semibold ">Profile</h1>
          {saved ? <CheckIcon className="w-5 h-5 ml-2 text-green-500" /> : <TypingDots />}
        </div>

        <div className="grid grid-cols-2 mt-5 gap-y-5 gap-x-3">
          {USER_ATTRIBUTE_MAP.map(({ label, key }) => (
            <div key={key} className={key === 'email' ? 'col-span-2' : ''}>
              <LabyrinthTextInput
                fieldInfo={{
                  title: label,
                  type: key === 'phone_number' ? 'phoneNumber' : 'textBox',
                  valueVariable: key,
                }}
                fieldValue={firmUser?.[key] ?? 'Not set'}
                handleOnChange={(params: LabyrinthUpdateParams) => {
                  // placeholder
                  setSaved(false);
                }}
                handleOnBlur={(params: LabyrinthUpdateParams) => {
                  userMutation.mutate({
                    data: {
                      [params.field.valueVariable]: params.value,
                    },
                  });
                  setSaved(true);
                }}
                key={key}
                disabled={key === 'email'}
              />
            </div>
          ))}
        </div>
      </SectionContainer>

      <SectionContainer>
        <h2 className="text-xl font-semibold leading-7 text-gray-900">Integrations</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Manage the integrations that are connected to your account.
        </p>

        <ul className="mt-6 text-sm leading-6 border-t border-gray-200 divide-y divide-gray-100">
          <li className="flex justify-between py-6 gap-x-6">
            <Typography>Adobe</Typography>
            {isAdobeUserLoading ||
            updateAdobeSignUserStatus.isLoading ||
            sendAuthedCode.isLoading ||
            createNewAdobeUserAccount.isLoading ||
            isAdobeAuthUrlLoading ||
            isAdobeUserListLoading ? (
              <div className="w-1/3 h-7">
                <TextSkeleton />
              </div>
            ) : (
              <div>
                {adobeUserAccountNotFound ||
                isAdobeUserError ||
                adobeUserAccountInactive ||
                adobeUserNeedsAuth ? (
                  <>
                    {(currentAdobeUser?.isAdmin || adobeUserNotFound) && (
                      <button
                        type="button"
                        onClick={() => {
                          if (adobeUserNeedsAuth && adobeAuthUrl) {
                            const adobeState = adobeAuthUrl.split('state=')[1];
                            localStorage.setItem('adobeState', adobeState);
                            if (!currentAdobeUser) {
                              localStorage.setItem('adobeCreateNewUser', '1');
                            }
                            window.open(adobeAuthUrl, '_self');
                          }

                          if (adobeUserAccountInactive) {
                            updateAdobeSignUserStatus.mutate({
                              status: 'ACTIVE',
                              username: firmUser?.username || '',
                            });
                          }

                          if (adobeUserNotFound) {
                            createNewAdobeUserAccount.mutate();
                          }
                        }}
                        className="px-2 py-1 font-semibold border rounded-md cursor-pointer text-atlantic-blue bg-light-blue border-atlantic-blue hover:text-white hover:bg-atlantic-blue"
                      >
                        Connect
                      </button>
                    )}
                    {adobeUserNeedsAuth && !currentAdobeUser?.isAdmin && (
                      <TooltipWrapper
                        message={'Please contact your Adobe administrator to enable Adobe'}
                      >
                        <div className="font-semibold text-gray-500 cursor-default ps-2">
                          <button
                            disabled
                            type="button"
                            className="px-2 py-1 font-semibold border rounded-md opacity-50 cursor-not-allowed text-atlantic-blue bg-light-blue border-atlantic-blue"
                          >
                            Connect
                          </button>
                        </div>
                      </TooltipWrapper>
                    )}
                  </>
                ) : (
                  <div className="space-x-5">
                    {adobeUserAccountCreated ? (
                      <TooltipWrapper
                        message={
                          'Please check your email and follow the instructions to complete your adobe onboarding'
                        }
                      >
                        <div className="px-2 font-semibold text-gray-500 cursor-default">
                          Created
                        </div>
                      </TooltipWrapper>
                    ) : (
                      <span className="px-2 font-semibold text-green-500 cursor-default">
                        Connected
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </li>
          <li className="py-6 gap-x-6">
            <div className="flex items-center justify-between">
              <Typography>Calendar</Typography>

              {nylasAccountQuery.isLoading ? (
                <div className="w-1/3 bg-gray-100 rounded-md h-7 animate-pulse"></div>
              ) : (
                <div>
                  {nylasAccountNotFound ||
                  nylasAccountQuery.isError ||
                  nylasAccount.nylasGrantStatus === 'invalid' ? (
                    <a
                      href={nylasAuthUrl}
                      className="px-2 py-2 font-semibold border rounded-md cursor-pointer text-atlantic-blue bg-light-blue border-atlantic-blue hover:text-white hover:bg-atlantic-blue"
                    >
                      Connect
                    </a>
                  ) : (
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="px-2 font-semibold text-green-500 cursor-default"
                      >
                        Connected
                      </button>
                      {primaryCalendarQueryNotFound && (
                        <div className="ml-3">
                          <TooltipWrapper
                            message={
                              'Although you have connected your personal account. \nA firm admin needs to create the firm calendar in the settings tab before you can use it.'
                            }
                          >
                            <ExclamationCircleIcon className="w-5 h-5 text-red-500" />
                          </TooltipWrapper>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </li>
          <li className="py-6 gap-x-6">
            <div className="flex items-center justify-between">
              <Typography>Microsoft One Drive</Typography>
              <MicrosoftLogin />
            </div>
          </li>
        </ul>
      </SectionContainer>
      <SectionContainer>
        <h2 className="text-xl font-semibold leading-7 text-gray-900">Time Log Preferences</h2>
        <TimeLogPreferences />
      </SectionContainer>
      <SectionContainer>
        <h2 className="text-xl font-semibold leading-7 text-gray-900">Notification Preferences</h2>
        <NotificationPreferences />
      </SectionContainer>
    </div>
  );
}

export default withErrorBoundary(ProfilePage, {
  fallback: ({ error }) => <CustomErrorBoundary error={error} />,
});

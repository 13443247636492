import {
  CompleteMedicalTreatmentType,
  cn,
  displayContactName,
  formatPrice,
  getAggregateValues,
  prettifyDateString,
} from '@colosseum/data';
import { TooltipWrapper, renderCell, renderHeader } from '@colosseum/shared-ui';
import { ContactType, RecordRequest } from '@gladiate/types';
import { DocumentCheckIcon, DocumentIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<
  CompleteMedicalTreatmentType & {
    billingEntity?: ContactType;
    recordRequests: RecordRequest[];
    medicalTreatmentTypeName?: string;
    contact?: ContactType;
  }
>[] = [
  {
    accessorFn: (row) => {
      return [displayContactName(row.billingEntity), row.recordRequests];
    },
    id: 'Billing Entity',
    enableHiding: false,
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const [contactName, recordRequests] = props.getValue<[string[], RecordRequest[]]>();
      const noRequests = recordRequests?.length === 0 || !recordRequests;

      const requestsExists = recordRequests?.length > 0;

      const allRequestsCompleted =
        recordRequests?.length > 0 &&
        recordRequests.every((request) => request?.requestStatus === 'completed');
      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Billing Entity>'}
          </span>

          <TooltipWrapper
            message={
              allRequestsCompleted
                ? 'All record requests for treatment are completed.'
                : noRequests
                ? 'No requests have been made for this treatment.'
                : 'Record request in progress.'
            }
          >
            <div className="relative ml-2">
              {noRequests && <DocumentIcon className="w-5 h-5 text-red-500" />}

              {allRequestsCompleted && <DocumentCheckIcon className="w-5 h-5 text-green-500" />}

              {requestsExists && !allRequestsCompleted && (
                <div>
                  <span className="absolute flex w-2 h-2 top-px right-0.5 ">
                    <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-blue"></span>
                    <span className="relative inline-flex w-2 h-2 rounded-full bg-sky-blue"></span>
                  </span>
                  <DocumentTextIcon className="w-5 h-5 text-gray-500" />
                </div>
              )}
            </div>
          </TooltipWrapper>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => {
      return row.medicalProvider ? displayContactName(row.medicalProvider) : undefined;
    },
    id: 'Medical Provider',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const contactName = props.getValue<string>();

      const textClasses = 'font-semibold';
      return (
        <div className="flex">
          <span className={cn(textClasses, !contactName && 'text-red-500')}>
            {contactName || '<No Medical Provider>'}
          </span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateCreated),
    id: 'Date Created',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.dateModified),
    id: 'Date Modified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => displayContactName(row.contact),
    id: 'Client',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.startingDateOfService),
    id: 'Starting Date of Service',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => prettifyDateString(row.endingDateOfService),
    id: 'Ending Date of Service',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.medicalTreatmentTypeName,
    id: 'Type of Treatment',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.numberOfVisits,
    id: 'Number of Visits',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => row.description,
    id: 'Description',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
  {
    accessorFn: (row) => {
      return row.surgeryPerformed === '1' ? row.surgeryCount : undefined;
    },
    id: 'Surgery Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.mriPerformed === '1' ? row.mriCount : undefined;
    },
    id: 'MRI Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.healthInsuranceCoverage === '1' ? row.healthInsurancePayments : undefined;
    },
    id: 'Health Insurance Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.clientPaidOutOfPocket === '1' ? row.clientPaidOutOfPocketPayment : undefined;
    },
    id: 'Client Paid out of Pocket',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.personalInjuryProtectionCoverage === '1'
        ? row.personalInjuryProtectionPayment
        : undefined;
    },
    id: 'PIP Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.medicalPaymentCoverage === '1' ? row.medicalPaymentAmount : undefined;
    },
    id: 'Med Pay Coverage',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return row.injectionPerformed === '1' ? row.injectionCount : undefined;
    },
    id: 'Injection Count',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? value.toLocaleString() : '-';
    },
  },
  {
    accessorFn: (row) => row.amountBilled,
    id: 'Amount Billed',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.adjustments,
    id: 'Adjustments',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => renderCell({ props, valueModifierFunc: formatPrice }),
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value > 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.totalPayments,
    id: 'Total Payments',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
    aggregationFn: (columnId, values) => {
      let total = 0;
      values.forEach((value) => {
        if (value.getValue(columnId)) {
          total += value.getValue<number>(columnId);
        }
      });
      return total;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => row.unpaidBalance,
    id: 'Unpaid Balance',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const unpaidBalance = props.getValue<number>();
      const inferredUnpaidBalance = props.row.original.inferredUnpaidBalance;
      if (typeof unpaidBalance === 'number') {
        return formatPrice(unpaidBalance);
      } else if (inferredUnpaidBalance) {
        return (
          <TooltipWrapper message="This value is assumed from the present balance, because there is no unpaid balance set.">
            <span className="text-yellow-500">{formatPrice(inferredUnpaidBalance)}</span>
          </TooltipWrapper>
        );
      } else {
        return '-';
      }
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return completeTreatments?.aggregateUnpaidBalance;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => {
      return [row.reductionAmount, row.reductionPercentage];
    },
    id: 'Reduction', // changing this has alot of side effects
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const [reductionAmount, reductionPercentage] = props.getValue<[number, number]>();
      const reductionAmountToDisplay =
        reductionAmount >= 0 ? formatPrice(Number(reductionAmount)) : '-';
      const reductionPercentageToDisplay =
        reductionPercentage >= 0 ? `${reductionPercentage.toFixed(2)}%` : '-';
      return (
        <div>
          <span>
            {reductionAmount >= 0
              ? `${reductionAmountToDisplay} (${reductionPercentageToDisplay})`
              : '-'}
          </span>
        </div>
      );
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return [
        completeTreatments?.aggregateReductions,
        completeTreatments?.aggregateReductionsPercent,
      ];
    },
    aggregatedCell: (props) => {
      const [reductionAmount, reductionPercentage] =
        props.getValue<[number | undefined, number | undefined]>();
      if (
        typeof reductionAmount == 'undefined' ||
        typeof reductionPercentage == 'undefined' ||
        reductionAmount < 0 ||
        reductionPercentage < 0
      ) {
        return '-';
      }
      return `${formatPrice(reductionAmount)} (${reductionPercentage.toFixed(2)}%)`;
    },
  },
  {
    accessorFn: (row) => row.presentBalance,
    id: 'Present Balance',
    sortUndefined: 1,
    header: renderHeader,
    cell: (props) => {
      const presentBalance = props.getValue<number>();
      const inferredPresentBalance = props.row.original.inferredPresentBalance;
      if (presentBalance) {
        return formatPrice(presentBalance);
      } else if (inferredPresentBalance) {
        return (
          <TooltipWrapper message="This value is assumed from the unpaid balance, because there is no present balance set.">
            <span className="text-yellow-500">{formatPrice(inferredPresentBalance)}</span>
          </TooltipWrapper>
        );
      } else {
        return '-';
      }
    },
    aggregationFn: (columnId, values) => {
      const treatments = values.map((value) => value.original);
      const completeTreatments = getAggregateValues(treatments);
      return completeTreatments?.aggregatePresentBalance;
    },
    aggregatedCell: (props) => {
      const value = props.getValue<number>();
      return value >= 0 ? formatPrice(value) : '-';
    },
  },
  {
    accessorFn: (row) => prettifyDateString(row.balanceVerifiedDate),
    id: 'Date Verified',
    sortUndefined: 1,
    sortingFn: 'dayjsDateTime',
    header: renderHeader,
    cell: (props) => renderCell({ props }),
  },
];

import {
  CompleteLienType,
  casesSectionMappings,
  enqueueAPISnackbar,
  mergeLiensAndContacts,
  scrollToCaseSection,
  tanstackTableNames,
  useCompleteLienValues,
  useCreateCaseContactConnectionWithItem,
  useCreateLien,
  useDeleteLien,
  useGetCaseContactConnections,
  useGetContactsInfinite,
  useGetLiens,
  useUpdateLien,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  LienForm,
  ResourceSlideover,
  SlideoverContext,
} from '@colosseum/shared-ui';
import { TabsContent } from '@radix-ui/react-tabs';
import { VisibilityState } from '@tanstack/react-table';
import { useContext, useEffect, useState } from 'react';
import { columns } from './lien-table-columns';
import { useParams } from 'react-router';

/* eslint-disable-next-line */
export interface LiensProps {
  caseId: string;
  expandAll?: boolean;
}

export function Liens(props: LiensProps) {
  const { expandAll } = props;
  const { caseId } = useParams<{ caseId: string }>();
  const { pendingSlideoverToOpen, setPendingSlideoverToOpen } = useContext(SlideoverContext);
  const [open, setOpen] = useState(false);
  const [activeLienId, setActiveLienId] = useState<string>();

  const [typing, setTyping] = useState(false);

  const [collapsibleOpen, setCollapsibleOpen] = useState(false);

  const { data: liensData, isLoading: liensLoading } = useGetLiens(caseId ?? '');
  const createLien = useCreateLien();
  const deleteLien = useDeleteLien();
  const updateLien = useUpdateLien();
  const { getCompleteLienValues } = useCompleteLienValues(caseId ?? '');

  const completeLiens = getCompleteLienValues(liensData?.data ?? []);

  const activeLien =
    completeLiens?.find((lien) => lien?.lienId === activeLienId) ?? ({} as CompleteLienType);

  const { data: caseContactConnectionsData } = useGetCaseContactConnections(caseId ?? '', 'cases');
  const { data: contactsData } = useGetContactsInfinite();

  const mergedClientLiens = mergeLiensAndContacts({
    caseContactConnections: caseContactConnectionsData?.data ?? [],
    contacts: contactsData?.data ?? [],
    liens: completeLiens ?? [],
  });

  const { createCaseContactConnectionWithItemSoleClient } =
    useCreateCaseContactConnectionWithItem();

  useEffect(() => {
    if (expandAll) {
      setCollapsibleOpen(true);
    } else {
      setCollapsibleOpen(false);
    }
  }, [expandAll]);

  const handleCreateLien = () => {
    caseId &&
      createLien
        .mutateAsync({
          caseId,
        })
        .then((res) => {
          setActiveLienId(res.data.lienId);
          setOpen(true);
          createCaseContactConnectionWithItemSoleClient({
            caseId,
            itemId: res.data.lienId,
            itemType: 'lien',
            roleOnCase: 'client',
            caseContactConnections: caseContactConnectionsData?.data,
          });
        });
  };

  const handleDeleteLien = () => {
    deleteLien.mutateAsync(activeLien?.lienId).then(() => {
      setOpen(false);
      setActiveLienId(undefined);
    });
  };

  function handleUpdateLien(val: string | number, key: string, lienId: string) {
    const lienUpdate = {
      lienId: lienId,
      [key]: val,
    };
    updateLien.mutate(lienUpdate);
  }

  useEffect(() => {
    if (pendingSlideoverToOpen?.type === 'lien' && !liensLoading) {
      if (liensData?.data.find((lien) => lien.lienId === pendingSlideoverToOpen?.id)) {
        const ref = document.querySelectorAll(
          `[data-case-submenu-item='${casesSectionMappings.negotiations}']`,
        )[0];
        scrollToCaseSection(ref, true);
        setOpen(true);
        setActiveLienId(pendingSlideoverToOpen?.id);
      } else {
        enqueueAPISnackbar({
          message: 'Lien not found (it may have been deleted).',
          variant: 'error',
        });
      }
      setPendingSlideoverToOpen(undefined);
    }
  }, [pendingSlideoverToOpen, liensLoading]);

  return (
    <>
      <ResourceSlideover
        open={open}
        setOpen={setOpen}
        title={'Lien'}
        description={'Add a new lien'}
        typing={typing}
        deleteFunction={handleDeleteLien}
        displayDeleteButton={true}
        createType="lien"
        resourceId={activeLien?.lienId}
        caseId={caseId}
      >
        <TabsContent value="details">
          <LienForm
            updateHandler={handleUpdateLien}
            lien={activeLien}
            caseId={caseId ?? ''}
            typing={typing}
            setTyping={setTyping}
          />
        </TabsContent>
      </ResourceSlideover>
      <div className="">
        <div>
          <div className="flex items-center justify-end w-full align-middle">
            <CreateButton
              title={'Add Lien'}
              loading={createLien.isLoading}
              onClick={handleCreateLien}
              dataCy="create-lien-button"
            />
          </div>
          <div className="pt-4">
            <DataTable
              data={mergedClientLiens ?? []}
              initialSort={{
                id: 'Date Created',
                desc: true,
              }}
              filters={[]}
              showSearchBar
              columns={columns}
              handleRowClick={(item) => {
                const group = item.getIsGrouped();
                if (!group) {
                  setActiveLienId(item.original.lienId);
                  setOpen(true);
                }
              }}
              initialVisibility={
                {
                  'Date Created': false,
                  'Date Modified': false,
                  'Filing Details': false,
                  'Date Verified': false,
                } as VisibilityState
              }
              persistentVisibility={{
                'Lien Holder': true,
              }}
              initialGroupingsExpanded={true}
              isLoading={liensLoading}
              tableName={tanstackTableNames.liens}
              autoResetExpanded={false}
              grouping={['Lien Holder']}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Liens;

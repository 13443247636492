import {
  tanstackTableNames,
  useCreateCaseStatus,
  useDeleteCaseStatus,
  useGetCaseStatusUpdates,
  useGetCaseStatuses,
  useUpdateCaseStatus,
} from '@colosseum/data';
import {
  CreateButton,
  DataTable,
  Form,
  SelectFormInput,
  Slideover,
  TextFormInput,
  getInitialVisibilityForTable,
} from '@colosseum/shared-ui';
import { CaseStatusType, CaseStatusUpdateType } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { columns } from './CaseStatusesTableColumns';

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  category: z.string(),
  goal: z.coerce.number().nonnegative().nullish().optional().or(z.nan()),
});

export function CaseStatuses() {
  const {
    data: caseStatusesData,
    isLoading: isCaseStatusesLoading,
    isError: isCaseStatusesError,
  } = useGetCaseStatuses(true, true);
  const { data: caseStatusUpdatesData } = useGetCaseStatusUpdates();
  const caseStatusUpdatesByStatus = caseStatusesData?.data?.reduce((acc, status) => {
    return {
      [status?.caseStatusId]: { updates: [], title: status?.title ?? '' },
      ...acc,
    };
  }, {}) as {
    [key: string]: {
      updates: CaseStatusUpdateType & { timeInStatus: number }[];
    };
  };
  const calculateTimeInStatus = (statusUpdate: CaseStatusUpdateType) => {
    return dayjs(statusUpdate.dateEnded).diff(statusUpdate.dateStarted, 'days') as number;
  };

  const aggregateAverageTimeInStatus = (
    updates: CaseStatusUpdateType & { timeInStatus: number }[],
  ) => {
    if (updates.length === 0) return '-';
    return Math.round(
      updates.reduce((acc, update) => acc + update.timeInStatus, 0) / updates.length,
    );
  };

  caseStatusUpdatesData?.data?.forEach((update) => {
    if (caseStatusUpdatesByStatus?.[`${update?.caseStatusId}`] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      caseStatusUpdatesByStatus?.[update?.caseStatusId]?.updates.push({
        ...update,
        timeInStatus: calculateTimeInStatus(update),
      });
    }
  });

  const createCaseStatus = useCreateCaseStatus();
  const updateCaseStatus = useUpdateCaseStatus();
  const deleteCaseStatus = useDeleteCaseStatus();
  const [openCaseStatus, setOpenCaseStatus] = useState<CaseStatusType | undefined>();
  const [open, setOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: {
      title: openCaseStatus?.title || '',
      description: openCaseStatus?.description || '',
      category: openCaseStatus?.category || '',
      goal: openCaseStatus?.goal,
    },
  });
  const typing = createCaseStatus.isLoading || updateCaseStatus.isLoading;

  const handleCreate = () => {
    createCaseStatus.mutateAsync({}).then((res) => {
      setOpenCaseStatus(res.data);
      setOpen(true);
    });
  };

  function handleUpdate(key: string, value: string | number) {
    updateCaseStatus.mutate({
      caseStatusId: openCaseStatus?.caseStatusId ?? '',
      [key]: value,
    });
  }

  useEffect(() => {
    if (!open) {
      form.reset();
      setOpenCaseStatus(undefined);
    }
  }, [open, form]);

  return (
    <>
      <Slideover
        displayDeleteButton={true}
        open={open}
        setOpen={setOpen}
        deleteFunction={() => {
          if (openCaseStatus?.caseStatusId)
            deleteCaseStatus.mutateAsync(openCaseStatus?.caseStatusId).then(() => {
              setOpenCaseStatus(undefined);
              setOpen(false);
            });
        }}
        title="Case Status"
        description="Update your case statuses' information here."
        typing={typing}
      >
        <Form {...form}>
          <form>
            <div className="flex flex-col mb-2 gap-x-3 gap-y-5">
              <TextFormInput
                {...form.register(`title`)}
                title="Title"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('title', target.value);
                }}
                maxLength={120}
              />
              <TextFormInput
                {...form.register(`description`)}
                title="Description"
                type="textarea"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('description', target.value);
                }}
              />
              <SelectFormInput
                title="Category"
                listItems={{
                  Open: 'open',
                  Referred: 'refer',
                  Declined: 'decline',
                  Closed: 'close',
                  Lead: 'lead',
                }}
                listItemsIsObject
                defaultValue={openCaseStatus?.category}
                handleOnChange={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('category', target.value);
                }}
                {...form.register('category')}
              />
              <TextFormInput
                {...form.register(`goal`)}
                title="Goal (days)"
                handleOnBlur={(e: React.SyntheticEvent) => {
                  const target = e.target as HTMLInputElement;
                  handleUpdate('goal', Number(target.value));
                }}
                type="number"
              />
            </div>
          </form>
        </Form>
      </Slideover>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 id="case-statuses" className="text-xl font-semibold text-gray-900">
              Case Statuses
            </h1>
            <p className="mt-2 text-sm text-gray-700">{`Your firm's case statuses.`}</p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <CreateButton
              title={'Create new status'}
              loading={createCaseStatus.isLoading}
              onClick={handleCreate}
            />
          </div>
        </div>
        <DataTable
          data={caseStatusesData?.data || []}
          initialSort={{
            id: 'Category',
            desc: false,
          }}
          columns={columns}
          isLoading={isCaseStatusesLoading}
          isError={isCaseStatusesError}
          tableName={tanstackTableNames.caseStatuses}
          handleRowClick={(item: Row<CaseStatusType>) => {
            if (!item.getIsGrouped()) {
              setOpenCaseStatus(item.original);
              setOpen(true);
            }
          }}
          initialVisibility={getInitialVisibilityForTable(tanstackTableNames.caseStatuses, {})}
          grouping={['Category']}
          autoResetExpanded={false}
          initialGroupingsExpanded={false}
        />
      </div>
    </>
  );
}

export default CaseStatuses;

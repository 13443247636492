/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  convertToE164,
  reduceCognitoAttributes,
  useUpdateFirmUserAttributes,
} from '@colosseum/data';
import { PhoneInput, SelectFormInput, TextFormInput } from '@colosseum/shared-ui';
import { GroupPermission, HurinUserType, LabyrinthUpdateParams } from '@gladiate/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';

export interface FirmUserFormProps {
  user?: HurinUserType;
  creatingNewUser: boolean;
  createUserPayload: HurinUserType;
  creatingUsername: string;
  firmGroups: GroupPermission[] | undefined;
  setTyping: Dispatch<SetStateAction<boolean>>;
  setCreatingUsername: Dispatch<SetStateAction<string>>;
}

const Form = FormProvider;

const formSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  phone_number: z.string().optional(),
  groupId: z.string().optional(),
});

export function FirmUserForm(props: FirmUserFormProps) {
  const { user, createUserPayload, creatingUsername } = props;

  const initialCognitoAttributes: any = user
    ? {
        ...reduceCognitoAttributes(user ?? {}),
        userName: user?.Username,
      }
    : {
        ...createUserPayload,
        userName: creatingUsername,
      };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    values: initialCognitoAttributes,
  });

  const userMutation = useUpdateFirmUserAttributes(user?.Username ?? '');

  function handleExistingUserUpdate(params: LabyrinthUpdateParams) {
    // if the field isnt different from the current value, dont update
    if (params.field.valueVariable === 'phone_number') {
      //remove all non-numeric characters from the phone number
      const strippedPhoneNumber = params.value.replace(/\D/g, '');

      if (
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `1${initialCognitoAttributes?.[params?.field?.valueVariable] || ''}` === strippedPhoneNumber
      ) {
        return;
      }
    }

    if (initialCognitoAttributes[params.field.valueVariable] === params.value) {
      return;
    }

    const userMutationArgs = {
      [params.field.valueVariable]:
        params.field.valueVariable === 'phone_number' ? convertToE164(params.value) : params.value,
    };

    userMutation.mutate({ data: userMutationArgs });
  }

  function handleNewUserUpdate(params: LabyrinthUpdateParams) {
    if (params.field.valueVariable === 'userName') {
      props.setCreatingUsername(params.value);
      return;
    }

    if (params.field.valueVariable === 'email') {
      if (params.value.includes('@')) {
        const username = params.value.split('@')[0];
        props.setCreatingUsername(username);
      }
    }
    // @ts-ignore
    props.createUserPayload[params.field.valueVariable as keyof typeof createUserPayload] =
      params.field.valueVariable === 'phone_number' ? convertToE164(params.value) : params.value;

    initialCognitoAttributes[params.field.valueVariable] = params.value;
  }

  function handleUpdate(params: LabyrinthUpdateParams) {
    if (!props.creatingNewUser) {
      handleExistingUserUpdate(params);
    } else {
      handleNewUserUpdate(params);
    }
    props.setTyping(false);
  }

  const handleInputChange = () => {
    props.setTyping(true);
  };

  return (
    <div>
      <Form {...form}>
        <form
          className="grid grid-cols-2 py-10 gap-x-3 gap-y-5 first:pt-4 last:pb-4"
          aria-label="form"
        >
          <TextFormInput
            {...form.register(`name`)}
            handleOnChange={handleInputChange}
            handleOnBlur={(e) => {
              handleUpdate({
                field: {
                  valueVariable: 'name',
                  type: 'text',
                  title: 'Name',
                },
                value: e.target.value,
              });
            }}
            name="name"
            title="Name"
          />

          <TextFormInput
            {...form.register(`email`)}
            handleOnChange={handleInputChange}
            handleOnBlur={(e) => {
              handleUpdate({
                field: {
                  valueVariable: 'email',
                  type: 'email',
                  title: 'Email',
                },
                value: e.target.value,
              });
            }}
            name="email"
            title="Email"
            disabled={!props.creatingNewUser}
          />

          <PhoneInput
            formHandleChange={handleInputChange}
            formHandleBlur={(e) => {
              handleUpdate({
                field: {
                  valueVariable: 'phone_number',
                  type: 'phoneNumber',
                  title: 'Phone Number',
                },
                value: convertToE164(e.target.value),
              });
            }}
            {...form.register(`phone_number`)}
            name="phone_number"
            title="Phone Number"
          />

          <SelectFormInput
            {...form.register(`groupId`)}
            defaultValue={
              props.firmGroups?.find((group) => group.groupId === user?.groupId)?.groupName ?? ''
            }
            listItems={props.firmGroups?.map((group) => group.groupName) ?? []}
            handleOnChange={(e) => {
              const groupId = props.firmGroups?.find(
                (group) => group.groupName === e.target.value,
              )?.groupId;

              handleUpdate({
                field: {
                  valueVariable: 'groupId',
                  type: 'dropdown',
                  title: 'Role',
                },
                value: groupId,
              });
            }}
            name="groupId"
            title="Role"
          />
        </form>
      </Form>
    </div>
  );
}

export default FirmUserForm;
